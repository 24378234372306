import React from "react"
import PropTypes from "prop-types"
import { Box, Flex, Image } from "theme-ui"
import logo from "../../common/images/ActionSA_Logo_Ext_01_green.png"
import { Text } from "../Text/Text"
import { Container } from "../../components/Grid"
import { language } from "../../common/utils/language"

export const Header = props => {
  const { color, bgColor } = props

  return (
    <Box bg={bgColor} sx={{ py: [1, "10px"] }} mb={[2]}>
      <Container>
        <Flex
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Image
            sx={{ height: ["60px", "80px"], transform: "scale(1.5)" }}
            src={logo}
            title="The People's Dialogue"
          />
          <Flex sx={{ alignItems: "center" }}>
            <Text color={color}>
              <i className="icon icon-secure" />
            </Text>
            <Text color={color} ml={[1]}>
              {language.secure}
            </Text>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

Header.propTypes = {
  color: PropTypes.string,
  bgColor: PropTypes.string,
}

Header.defaultProps = {
  color: "textLight",
  bgColor: "brandGreen",
}
