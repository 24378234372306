import React from "react"
import { Text as ThemedText } from "theme-ui"

export const Text = props => {
  const { children, ...rest } = props
  return <ThemedText {...rest}>{children}</ThemedText>
}

Text.defaultProps = {
  variant: "body",
}
