import React from "react"
import { Flex } from "theme-ui"

export const Column = props => {
  const { children, width, ...rest } = props
  return (
    <Flex sx={{ flex: "1 1 auto", width: width }} {...rest}>
      {children}
    </Flex>
  )
}

Column.defaultProps = {
  px: "15px",
}
