import React from "react"
import PropTypes from "prop-types"
import { Button as ThemedButton } from "theme-ui"

export const Button = props => {
  const { isActive, isBlock, children, ...rest } = props
  return (
    <ThemedButton
      sx={{ width: isBlock ? "100%" : "inherit", cursor: "pointer" }}
      isActive={isActive}
      {...rest}
    >
      {children}
    </ThemedButton>
  )
}

Button.propTypes = {
  isBlock: PropTypes.bool,
  isActive: PropTypes.bool,
}

Button.defaultProps = {
  isBlock: false,
  isActive: false,
}
