import React from "react"
import PropTypes from "prop-types"
import { Box, Flex, Image } from "theme-ui"
import logo from "../../common/images/ActionSA_ICON_03.png"
import { Text } from "../Text/Text"
import { Container } from "../../components/Grid"
import styled from "@emotion/styled"
import { language } from "../../common/utils/language"

const StyledFooter = styled(Box)`
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

export const Footer = props => {
  const { color, ...rest } = props

  return (
    <Box mt={[3, 4]} {...rest}>
      <Container>
        <StyledFooter sx={{ mt: [1, "30px"] }}>
          <Flex
            sx={{
              alignItems: "center",
              flexDirection: "row",
              py: [2, 3],
            }}
          >
            <Image
              sx={{ height: ["30px"] }}
              src={logo}
              title="The People's Dialogue"
            />
            <Text variant="small" color={color} ml={[1]}>
              {language.footer.notice}
            </Text>
          </Flex>
        </StyledFooter>
      </Container>
    </Box>
  )
}

Footer.propTypes = {
  color: PropTypes.string,
}

Footer.defaultProps = {
  color: "textLight",
}
