import React from "react"
import { Box } from "theme-ui"

export const Container = props => {
  const { children, ...rest } = props
  return (
    <Box sx={{ maxWidth: "1220px" }} {...rest}>
      {children}
    </Box>
  )
}

Container.defaultProps = {
  mx: "auto",
  px: "15px",
}
