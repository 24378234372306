export const language = {
  secure: "Secure",
  cancel: {
    headline: "Cancel recurring donation",
    support_url: "info@thepeoplesdialogue.org.za",
    formTitle: "Are you sure you want to cancel your donation?",
    submit: "Confirm",
    confirm: "Your recurring donation has been cancelled.",
  },
  donation: {
    headline: "Your donation makes a difference",
    labelAmount: "Donation Amount",
    labelAmountOther: "Other Amount",
    labelRecurring: "Repeat this donation monthly",
    buttonNext: "Next",
  },
  contactInfo: {
    headline: "Contact Information",
    labelName: "Name",
    labelSurname: "Surname",
    labelIdNumber: "ID Number",
    labelEmail: "Email",
    labelCellphone: "Cellphone",
    buttonNext: "Next",
    resident: "I am a South African citizen or permanent resident",
  },
  paymentInfo: {
    headline: "Payment Information",
    labelNumber: "Card Number*",
    labelMonth: "Expiry month*",
    labelYear: "Expiry year*",
    labelCvc: "CCV/CVC*",
    buttonNext: "Next",
    eftOption: "Make an EFT Donation",
    ccOption: "Make a Credit Card Donation",
  },
  success: {
    headline: "Spread the word",
    buttonFacebook: "Share on Facebook",
    buttonTwitter: "Share on Twitter",
    buttonWhatsapp: "Share on WhatsApp",
    buttonEmail: "Email a Friend",
  },
  footer: {
    notice: "ActionSA © 2020 Copyright",
  },
  petition: {
    buttonNext: "Submit",
  },
  form: {
    required: "Required Field",
    validateEmail: "Incorrect email address",
    validateCellphone: "Incorrect cellphone number",
  },
  brand: "Card Brand",
  cardNumber: "Card Number",
  expiry: "Expiry Date",
  cardHolder: "Card Holder Name",
  submit: "Donate",
  loading: "Loading...",
}

export const contentApi = {
  headline:
    "We cannot wait for the NPA any longer! Mashaba initiates Private Prosecutions against corrupt politicians.",
  content:
    "After listening to the views of South Africans, I can announce that The People’s Dialogue will be pursuing private prosecutions against a host of corrupt politicians, working with other like-minded organizations committed to  rooting out corruption in our country.",
  videoId: "520t152jw7",
  success: {
    headline: "Thank you for your contribution.",
    content:
      "Thank you for your contribution to helping to put the corrupt in jail, where they belong. Please share this with others so that we do not rest until we have stopped the cancer of corruption.",
    videoId: "520t152jw7",
  },
}
